// tree-list

ul,
#myUL {
  list-style-type: none;
}

#myUL {
  margin: 0;
  padding: 0;
}

.box {
  cursor: pointer;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
  display: flex;
}

.check-box::before {
  content: '\2611';
  color: dodgerblue;
}

.nested {
  display: none;
}

.active {
  display: block;
}

/* width */

// ::-webkit-scrollbar {
//   width: 3px;
//   background: rgba(59, 130, 246, 0.5)   /* make scrollbar transparent */
// }

#journal-scroll::-webkit-scrollbar {
  width: 4px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
#journal-scroll::-webkit-scrollbar-track {
  background-color: #caddf3;
  cursor: pointer;
  /*background: red;*/
}
#journal-scroll::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: rgba(59, 130, 246, 0.4);
  /*outline: 1px solid slategrey;*/
}

.NoteMenu:after,
.NoteMenu:before {
  bottom: 100%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.NoteMenu:after {
  // border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 12px;
  left: 9%;
  margin-left: 149px;
}
.NoteMenu:before {
  // border-color: rgba(113, 158, 206, 0);
  border-bottom-color: #000000;
  opacity: 7%;
  border-width: 14px;
  left: 9%;
  margin-left: 149px;
}

.Search:after,
.Search:before {
  bottom: 100%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.Search:after {
  // border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 10px;
  left: 9%;
  margin-left: 14px;
}
.Search:before {
  // border-color: rgba(113, 158, 206, 0);
  border-bottom-color: #000000;
  opacity: 7%;
  border-width: 12px;
  left: 9%;
  margin-left: 14px;
}

// new item

.toggle-checkbox:checked {
  right: 0;
  border-color: #83aff6;
}
.toggle-checkbox:checked + .toggle-label {
  background-color: #83aff6;
}
